'use client';

import React from 'react';
import { RecoilRoot } from 'recoil';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '@queries/queryClient';
import Confirm from 'ui/context/confirm/Confirm';
import Loading from 'ui/context/loading/Loading';
import Alert from 'ui/context/alerts/Alert';
import Toast from 'ui/context/toast/Toast';
import { GlobalContextProvider } from 'ui/context/GlobalContext';

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <GlobalContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          {children}
          <Confirm />
          <Alert />
          <Loading />
          <Toast />
        </GlobalContextProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default Providers;
