import { useEffect } from 'react';
import ChannelService from '@hook/useChannelTalk/ChannelService';
import { useQueryGetMarketingPlugin } from '@queries/marketing';
import { getHostName } from '@utils/windowUtil';
import { channelTalkActiveStatusAtom } from '@recoil/channelTalkActiveStatusAtom';
import { useSetRecoilState } from 'recoil';
import { notFound, usePathname, useRouter } from 'next/navigation';

const useChannelTalk = () => {
  const pathname = usePathname();
  const setChannelTalkActiveStatus = useSetRecoilState(channelTalkActiveStatusAtom);

  const { data: queryGetMarketingPluginV1Data, isError } = useQueryGetMarketingPlugin(
    getHostName(),
    pathname !== '/notfound'
  );

  useEffect(() => {
    if (!queryGetMarketingPluginV1Data || isError) return;
    const channelService = new ChannelService();
    channelService.loadScript();

    channelService.boot(
      {
        pluginKey: queryGetMarketingPluginV1Data.channelTalk.pluginKey,
      },
      (error) => {
        if (error) return;
        setChannelTalkActiveStatus(true);
      }
    );
  }, [queryGetMarketingPluginV1Data]);
};

export default useChannelTalk;
