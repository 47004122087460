// Created by kdw0601 on 2023-02-01
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Icon, { IconTypes } from '../../common/icon/Icon';
import styles from './ToastList.module.scss';
import classNames from 'classnames/bind';
import IToastMessageProps, { ToastIconType } from './IToastMessageProps';
import Button from '../../common/button/Button';

const cx = classNames.bind(styles);

const toastTypeIcon: { [key in ToastIconType]: IconTypes } = {
  POSITIVE: 'check_circle_line',
  WARNING: 'alert_line',
  FAILED: 'alert_line',
};

export interface IToastRefType {
  closeToast: () => void;
}

interface IProps extends IToastMessageProps {
  onUnmount?: () => void;
  className?: string;
}

const ToastList = forwardRef<IToastRefType, IProps>(function Toast(
  { icon, className, text, durationMillisec = 0, onUnmount, closable, buttons, width, type, bottomOffset = 24 },
  ref
) {
  const [isVisible, setIsVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  useImperativeHandle(ref, () => ({
    closeToast,
  }));

  useEffect(() => {
    setTimeoutId(
      setTimeout(() => {
        setIsVisible(true);

        if (durationMillisec > 0) {
          setTimeout(() => {
            setIsVisible(false);
          }, durationMillisec);
        }
      }, 200)
    );
  }, []);

  const handleTransitionEnd = (e: React.TransitionEvent) => {
    if (!isVisible && e.propertyName === 'opacity') {
      onUnmount?.();
    }
  };

  const closeToast = () => {
    timeoutId && clearTimeout(timeoutId);
    setIsVisible(false);
  };

  return (
    <div
      className={cx('container', className, icon && icon.toLowerCase(), type, { visible: isVisible })}
      onTransitionEnd={handleTransitionEnd}
      style={{ bottom: isVisible ? bottomOffset : bottomOffset - 24, minWidth: width }}
      data-testid={'toast'}
    >
      <div className={cx('left')}>
        {icon && <Icon className={cx('icon')} size={24} name={toastTypeIcon[icon]} />}
        <span className={cx('text')}>{text}</span>
      </div>
      {(buttons || closable) && (
        <div className={cx('button-wrap')}>
          {buttons?.map((v, i) => (
            <Button
              onClick={(e) => {
                e.currentTarget.blur();
                v.onClick ? v.onClick(closeToast) : closeToast();
              }}
              key={i}
              size={'sm'}
              variant={v.variant}
              color={v.color ?? 'dark'}
              load={v.load}
            >
              {v.text}
            </Button>
          ))}
          {closable && (
            <Button variant={'primary'} className={cx('close-button')} size={'sm'} color={'teal'} onClick={closeToast}>
              확인
            </Button>
          )}
        </div>
      )}
    </div>
  );
});

export default ToastList;
