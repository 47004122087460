import { SOLUTION_TYPE } from '../util/solution';
import { SOLUTION, SolutionType } from './solution/solution.constant';

export const ENV = {
  DV: 'development',
  ST: 'staging',
  ST2: 'staging2',
  PR: 'production',
} as const;

export type EnvType = 'production' | 'staging' | 'staging2' | 'development';

// eslint-disable-next-line turbo/no-undeclared-env-vars
export const ENV_TYPE: EnvType = (process?.env?.NEXT_PUBLIC_ENV ?? ENV.DV) as EnvType;

export const CDN_HOST: Record<SolutionType, Record<EnvType, string>> = {
  [SOLUTION.JOBDA]: {
    [ENV.DV]: 'ats-static.ats.kr-dv-jainwon.com',
    [ENV.ST]: 'ats-static.ats.kr-st-jainwon.com',
    [ENV.ST2]: 'st2-ats-static.ats.kr-st-jainwon.com',
    [ENV.PR]: 'ats-static.match.jobda.im',
  },
  [SOLUTION.JOBFLEX]: {
    [ENV.DV]: 'infra1-static.kr-dv-midasitwebsol.com',
    [ENV.ST]: 'infra1-static.midasweb.net',
    [ENV.ST2]: 'st2-infra1-static.midasweb.net',
    [ENV.PR]: 'infra1-static.recruiter.co.kr',
  },
};

export const CDN_URL = `${CDN_HOST[SOLUTION_TYPE][ENV_TYPE]}`;
