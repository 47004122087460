import { viewModeAtom } from '@recoil/viewAtom';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import useViewMode from 'ui/hook/useViewMode';

const useRecruitViewMode = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { viewMode } = useViewMode({ ref });
  const [, setViewModeAtom] = useRecoilState(viewModeAtom);
  const [_, refresh] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      refresh(true);
    }, 50);
  }, [ref.current]);

  useEffect(() => {
    if (!viewMode) return;
    setViewModeAtom(viewMode);
  }, [viewMode]);

  return { ref };
};

export default useRecruitViewMode;
