import React from 'react';
import classnames from 'classnames/bind';
import style from './Alert.module.scss';
import Button from '../../common/button/Button';
import Modal from '../modal/Modal';
import Paragraph from '../../common/paragraph/Paragraph';
import { AlertType } from './useAlertContextValue';
import useAlert from '../../hook/useAlert';

const cx = classnames.bind(style);

const Alert = () => {
  const { alert, setAlert } = useAlert();

  if (!alert) return null;

  const { title, description, btnTitle, onClick, closeBtnTitle } = alert;

  return (
    <AlertComponent
      title={title}
      description={description}
      btnTitle={btnTitle}
      onClick={onClick}
      setAlert={setAlert}
      closeBtnTitle={closeBtnTitle}
    />
  );
};

interface IAlertComponent {
  title: string;
  description: string | React.ReactNode;
  btnTitle?: string;
  onClick?: (closeAlert: () => void) => void;
  closeBtnTitle?: string;
  setAlert: (alert: AlertType | null) => void;
}
export const AlertComponent = ({ title, description, btnTitle, onClick, setAlert, closeBtnTitle }: IAlertComponent) => {
  const handleClose = () => {
    setAlert(null);
  };

  const handleConfirm = async () => {
    try {
      onClick ? await onClick(() => setAlert(null)) : setAlert(null);
    } catch (e) {
      throw e;
    }
  };

  return (
    <Modal contentClassName={cx('alert')} confirm pressEnter={handleConfirm} close={handleClose}>
      <p data-testid={'alert'} className={cx('alert-title')}>
        {title}
      </p>
      <Paragraph className={cx('description')} variant={'B3'}>
        {description}
      </Paragraph>
      <div className={cx('button-container')}>
        {closeBtnTitle && (
          <Button onClick={handleClose} variant={'text'}>
            {closeBtnTitle ?? '취소'}
          </Button>
        )}
        <Button onClick={handleConfirm} size={'md'} className={cx('button-confirm')}>
          {btnTitle ?? '확인'}
        </Button>
      </div>
    </Modal>
  );
};

export default Alert;
