'use client';

import classNames from 'classnames/bind';
import styles from './Confirm.module.scss';
import useConfirm from 'ats-editor/src/hook/useConfirm';
import Button from '../../common/button/Button';
import Icon from '../../common/icon/Icon';
import Modal from '../modal/Modal';
import Paragraph from '../../common/paragraph/Paragraph';
import { ConfirmType } from './useConfirmContextValue';

const cx = classNames.bind(styles);

const Confirm = () => {
  const { confirm, setConfirm } = useConfirm();

  if (!confirm) return null;

  const { title, description, confirmTitle, cancelTitle, confirmClick, cancelClick } = confirm;

  return (
    <ConfirmComponent
      setConfirm={setConfirm}
      title={title}
      description={description}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      confirmClick={confirmClick}
      cancelClick={cancelClick}
    />
  );
};

interface IConfirmComponent {
  setConfirm: (arg: ConfirmType | null) => void;
  title: string;
  description: string;
  confirmTitle?: string;
  cancelTitle?: string;
  confirmClick: (closeConfirm: () => void) => void;
  cancelClick?: (closeConfirm: () => void) => void;
}
export const ConfirmComponent = ({
  setConfirm,
  title,
  description,
  confirmTitle,
  cancelTitle,
  confirmClick,
  cancelClick,
}: IConfirmComponent) => {
  const handleClose = () => setConfirm(null);

  const handleCancel = async () => {
    cancelClick ? await cancelClick(() => setConfirm(null)) : setConfirm(null);
  };

  const handleConfirm = async () => {
    try {
      await confirmClick(() => setConfirm(null));
    } catch (e) {
      throw e;
    }
  };

  return (
    <Modal contentClassName={cx('confirm')} close={handleClose} pressEnter={handleConfirm} confirm>
      <button className={cx('closeBtn')} onClick={handleClose}>
        <Icon name={'close_line'} size={24} />
      </button>
      <p className={cx('alert-title')}>{title}</p>
      <Paragraph className={cx('description')} variant={'B3'}>
        {description}
      </Paragraph>
      <div className={cx('button-container')}>
        <Button onClick={handleCancel} size={'md'} variant={'text'} className={cx('button-cancel')}>
          {cancelTitle ?? '취소'}
        </Button>
        <Button onClick={handleConfirm} size={'md'} className={cx('button-confirm')}>
          {confirmTitle ?? '확인'}
        </Button>
      </div>
    </Modal>
  );
};

export default Confirm;
