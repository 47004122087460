import { useContext } from 'react';
import GlobalContext from 'ui/context/GlobalContext';

const useConfirm = () => {
  const {
    confirm: { confirm, setConfirm },
  } = useContext(GlobalContext);

  const closeConfirm = () => {
    setConfirm(null);
  };

  return {
    confirm,
    setConfirm,
    closeConfirm,
  };
};

export default useConfirm;
