import { useEffect } from 'react';
import { setFontFace } from 'ui/util/font.utils';

const useFontFace = () => {
  useEffect(() => {
    setFontFace();
  }, []);
};

export default useFontFace;
