import React, { useContext } from 'react';
import GlobalContext from '../GlobalContext';
import ToastList from './ToastList';

const Toast = () => {
  const {
    toast: { toastList, setToastList },
  } = useContext(GlobalContext);

  return (
    <>
      {toastList.map((toast) => (
        <ToastList
          {...toast}
          key={toast.key}
          ref={(el) => (toast.ref = el)}
          onUnmount={() =>
            setToastList((list: any) => {
              return list.filter(({ key }: any) => key !== toast.key);
            })
          }
        />
      ))}
    </>
  );
};

export default Toast;
