'use client';

import useRecruitViewMode from '@hook/useRecruitViewMode';
import useFontFace from 'ui/hook/useFontFace';
import useChannelTalk from '@hook/useChannelTalk/useChannelTalk';

interface IProps {
  children: React.ReactNode;
}

const CommonHookContainer = ({ children }: IProps) => {
  useFontFace();
  useChannelTalk();

  const { ref } = useRecruitViewMode();

  return <div ref={ref}>{children}</div>;
};

export default CommonHookContainer;
