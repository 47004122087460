import { CDN_URL } from '../constants/env.constant';
import { FONT_FAMILY, FONT_FAMILY_GROUP, IFontInfo } from '../constants/font.constant';

const getFontDetail = ({ fontFamily, fontName, fontWeight }: IFontInfo) => {
  return `
  @font-face {
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    font-display: swap;
    src: url('https://${CDN_URL}/fonts/${fontName}.woff2') format('woff2');
  }`;
};

export const totalFontFaceRule = FONT_FAMILY.reduce((fontFamilyInfoAcc, fontFamily) => {
  const totalInfoOfFontFamily = FONT_FAMILY_GROUP[fontFamily].reduce((fontInfoAcc, fontInfo) => {
    return fontInfoAcc + '\n' + getFontDetail({ ...fontInfo, fontFamily });
  }, '');

  return fontFamilyInfoAcc + totalInfoOfFontFamily;
}, '');

export const setFontFace = () => {
  window?.document.querySelector('body')?.insertAdjacentHTML('beforeend', `<style>${totalFontFaceRule}</style>`);
};
